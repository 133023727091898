import type { FooterMenu,SocialLinks } from '@/types/footer/index';
import { BrandFacebookIcon, BrandLinkedinIcon, BrandTwitterIcon } from 'vue-tabler-icons';

const FooterMenu1: FooterMenu[] = [
    {
        title: 'Home',
        url: '/'
    },
    {
        title: 'About us',
        url: '/about-us'
    }
]

const FooterMenu2: FooterMenu[] = [
    {
        title: 'Founders',
        url: '/founders'
    },
    {
        title: 'Investors',
        url: '/investors'
    },
    {
        title: 'Workers',
        url: '/workers'
    }
]

const FooterMenu3: FooterMenu[] = [    
    {
        title: 'Blog',
        url: '/blog'
    }
]

const SocialLinksIcons: SocialLinks[] = [
    {
        icon: BrandTwitterIcon,
        url:'#',
    },
    {
        icon: BrandLinkedinIcon,
        url:'#',
    }
]


export { FooterMenu1,FooterMenu2,FooterMenu3,SocialLinksIcons }