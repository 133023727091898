<script setup lang="ts">
import Logo from "@/layouts/logo/logo.vue";
import SnackbarComponent from "@/common/SnackbarComponent.vue";
import {
  FooterMenu1,
  FooterMenu2,
  FooterMenu3,
  SocialLinksIcons,
} from "@/_mockApis/footer/index";

let snackbar = ref({
  show: false,
  message: null,
  color: null,
  timeout: 0,
});

import { guestProfile } from "@/store/guestProfile";
const useGuestProfileStore = guestProfile();
let storeOne = useGuestProfileStore.one;

const emailRules = ref([
  (v: string) => !!v || "E-mail is required",
  (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
]);

// const runtimeConfig = useRuntimeConfig();

const Regform = ref();

// const appId = computed(() => {
//   return runtimeConfig.public.NUXT_MY_APP_ID;
// });

const appId = ref("tlet");

async function submitForm() {
  try {
    const { valid } = await Regform.value.validate();
    if (valid) {
      let user = await useGuestProfileStore.save({ appId: appId.value });
      Regform.value.reset(); // Reset the form after successful submission
      snackbar.value = {
        message: "You have Subscribed successfully",
        color: "success",
        show: true,
        timeout: 2000,
      };
    }
  } catch (error: any) {
    snackbar.value = {
      message: "You have Unsubscribe.",
      color: "error",
      show: true,
      timeout: 2000,
    };
    console.log(error);
  }
}
</script>
<template>
  <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  <section class="footer position-relative py-md-12 py-8">
    <v-container>
      <v-row>
        <v-col cols="12" md="5">
          <Logo />
        </v-col>
        <v-col cols="12" md="7" class="d-none d-sm-block">
          <v-row class="pb-7 mt-5">
            <v-col cols="6" sm="4">
              <h5 class="text-body-1 text-dark font-weight-medium mb-5">
                Main
              </h5>
              <ul>
                <li class="mb-2" v-for="link in FooterMenu1" :key="link.title">
                  <NuxtLink
                    :to="link.url"
                    class="text-muted text-decoration-none text-capitalize font-weight-regular position-relative"
                    >{{ link.title }}</NuxtLink
                  >
                </li>
              </ul>
            </v-col>
            <v-col cols="6" sm="4">
              <h5 class="text-body-1 text-dark font-weight-medium mb-5">
                Proposition
              </h5>
              <ul>
                <li
                  class="mb-2"
                  v-for="link2 in FooterMenu2"
                  :key="link2.title"
                >
                  <NuxtLink
                    :to="link2.url"
                    class="text-muted text-decoration-none text-capitalize font-weight-regular position-relative"
                    >{{ link2.title }}</NuxtLink
                  >
                </li>
              </ul>
            </v-col>
            <v-col cols="6" sm="4">
              <h5 class="text-body-1 text-dark font-weight-medium mb-5">
                Latest
              </h5>
              <ul>
                <li
                  class="mb-2"
                  v-for="link3 in FooterMenu3"
                  :key="link3.title"
                >
                  <NuxtLink
                    :to="link3.url"
                    class="text-muted text-decoration-none text-capitalize font-weight-regular position-relative"
                    >{{ link3.title }}</NuxtLink
                  >
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <ul class="d-flex align-center">
            <li v-for="social in SocialLinksIcons" class="mr-4">
              <NuxtLink :to="social.url" class="text-decoration-none">
                <v-avatar size="30" color="primary" class="rounded-md">
                  <component :is="social.icon" stroke-width="2" size="22" />
                </v-avatar>
              </NuxtLink>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="7">
          <v-form ref="Regform">
            <div class="d-sm-flex align-center newsletter">
              <h5
                class="text-body-1 text-dark font-weight-medium mr-sm-12 mb-sm-0 mb-4"
              >
                Newsletter
              </h5>

              <div class="d-flex align-center flex-fill">
                <v-text-field
                  v-model="storeOne.email"
                  :rules="emailRules"
                  variant="outlined"
                  density="comfortable"
                  color="white"
                  class="position-relative rounded-md text-white"
                  label="Enter Your Email address"
                  single-line
                  hide-details
                >
                </v-text-field>
                <v-btn
                  @click="submitForm"
                  color="primary"
                  size="large"
                  class=""
                  variant="flat"
                  >Subscribe</v-btn
                >
              </div>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>